import queryString from 'query-string'
import has from "lodash/has"
import first from "lodash/first"
import { ORDERABLE_STATES, MAX_PRODUCT_VARIANTS } from 'utils/constants'
import * as algolia from 'services/algolia'
import { getConfigOptions } from 'global-content/config'
import { formatOptions } from 'models/product'
import { translate } from 'utils/translate'
import sortBy from 'lodash/sortBy'
import { getProduct } from 'services/api'
import { AVAILABILITY_NOTAVAILABLE } from 'utils/constants'
import { toDetailProduct } from 'models/product'

const LEVEL_OPTION_SIZE_TYPE = `sizeType`
const LEVEL_OPTION_SIZE = `size`

export function getSlugsForFirstAvailableVariant(detailProduct, selected = {}) { // TODO: create unit test for this.
  // returns the option slugs (ex: { color: 'black', sizeType: 'wide' }) for first
  // variant for given detailProduct.
  // if selected options is provided, it will try use it (when valid)
  let firstVariantOptions = {}
  // debugger
  let options = detailProduct.options

  let optionLevels = detailProduct.optionLevels
  // if (
  //   options?.length === 1 &&
  //   first(options)?.options?.length === 1
  // ) {
  //   // If there is only one option, let it be auto selected
  //   optionLevels = detailProduct.optionLevels
  // } else {
  //   // Option levels represent an array of options to auto select from: color, sizeType, size
  //   // We want to automatically select a color, but we dont want to auto select a size
  //   // so we can prompt the user for an action to explicitly choose their size.
  //   optionLevels = pruneAutoSelectOptionLevels(detailProduct.optionLevels, selected)
  // }


  for (let i = 0; i < optionLevels.length; i++) {
    let currLevel = optionLevels[i]
    if (currLevel in selected) {
      // verifies that selected is actually an valid option by productId if top option else just by slug for lower options
      const o = options.find(option => option.productSlug ? (
        option.productSlug === `${detailProduct.id}.${selected[currLevel]}`
      ) : (
        option.slug === selected[currLevel]
      ))

      if (o) {
        firstVariantOptions[currLevel] = selected[currLevel]
        options = o.options
        continue
      }
      // falls thru when not found
    }

    // else pick first available option
    const {
      slug,
      options: nextOptions,
    } = getNextAvailableOption(options

      // On the first level, make sure it's scanning the same products
      .filter(v => !v.productSlug || v.productSlug.startsWith(`${detailProduct.id}.`))
    ) || {}
    firstVariantOptions[currLevel] = slug
    options = nextOptions
  }

  return firstVariantOptions
}

export function getOptionValues(detailProduct, selected = {}) {
  // returns option values to populate option selectors. it will use the
  // selected option (when valid) to go to the next level. otherwise, it
  // will select the first available option of that level

  const {
    id,
    optionLevels,
  } = detailProduct

  // can populate first level by default
  let levelOptions = {
    [optionLevels[0]]: detailProduct.options,
  }

  for (let i = 1; i < optionLevels.length; i++) {
    const parentLevel = optionLevels[i - 1]
    const currentLevel = optionLevels[i]
    const selectedSlug = selected[parentLevel]

    // Options might or might not be normalized depending on the flow
    const parentOptions = Object.values(levelOptions)[i - 1]
      .filter(v => !v.productSlug || v.productSlug.startsWith(`${id}.`))

    const options = getSelectedOptions(parentOptions, selectedSlug)

    levelOptions[currentLevel] = options
  }

  return levelOptions
}

export function getSlugsFromSku(
  tree,
  keys,
  sku,
  depth = 0,
  selected = {}
) {
  // returns selected option slugs (ex: { color: 'black', size: 's' }) for the
  // corresponding sku by DFS-traversing options tree
  const level = keys[depth]
  for (let i = 0; i < tree.length; i++) {
    const newSelected = { ...selected, [level]: tree[i].slug } // add current level/slug
    if (`options` in tree[i]) {
      const found = getSlugsFromSku(tree[i].options, keys, sku, depth + 1, newSelected)
      if (found) { // this controls that only matching sku gets bubbled up
        return found
      }
    } else if (`sku` in tree[i] && tree[i].sku === sku) {
      return newSelected
    }
  }
  return null
}

export function getSelectedSlugs(product, search) {
  const {
    options,
    optionLevels,
  } = product
  const {
    sku,
    ...levels
  } = queryString.parse(search)
  let selectedSlugs = { ...levels }

  if (sku) {
    const selectedOptions = getSlugsFromSku(options, optionLevels, sku)

    if (selectedOptions) {
      return selectedOptions
    }
  }

  return getSlugsForFirstAvailableVariant(product, selectedSlugs)
}

function getNextAvailableOption(options = []) {
  if (options.length === 1 && has(first(options), `size`)) {
    return first(options)
  }
  // debugger
  const availableOption = options.find(option => {
    return (
      ORDERABLE_STATES.includes(option.availability) &&
      has(option, `size`) === false
    )
  })
  //  || options[0]
  // debugger
  return availableOption
}


function getSelectedOptions(levelOptions, selectedSlug) {
  const selectedLevel = levelOptions.find(option => option.slug === selectedSlug)

  if (selectedLevel) {
    return selectedLevel.options
  }

  return []
}

export async function getGroupProductFallback({
  productId,
  language,
  getPromotions,
  search,
}) {
  if (getConfigOptions(`BSCfallback`)) {
    const groupCollectionTag = `_grp_${productId}`
    const { hits } = await algolia.getProducts({
      analytics: false,
      clickAnalytics: false,
      index: window.$content.algolia.bestMatch,
      options: {
        hitsPerPage: MAX_PRODUCT_VARIANTS,
        distinct: 1,
        filters: {
          collections: [groupCollectionTag],
        },
      },
    })
    if (hits.length > 0) {
      const id = hits[0].productId
      const product = await getProduct({ productId: id, language, getPromotions, search })
      return product
    } else {
      throw new Error(`No group product found`)
    }
  } else {
    throw new Error(`No product found`)
  }
}

export async function normalizeProductVariations({
  product,
  language,
}) {
  const groupCollectionTag = product.collections.find(collection => collection.tag?.startsWith(`_grp_`))?.tag

  if (groupCollectionTag) {
    const { hits } = await algolia.getProducts({
      analytics: false,
      clickAnalytics: false,
      language,
      index: window.$content.algolia.bestMatch,
      options: {
        hitsPerPage: MAX_PRODUCT_VARIANTS,
        distinct: 1,
        filters: {
          collections: [groupCollectionTag],
        },
      },
    })
    if (hits.length > 1) {
      const productOptions = hits.filter(hit => product.id !== hit.productId).reduce((acc, hit) => {
        let { options } = hit
        options = formatOptions(options.filter(option => !option.productId)).map(option => translate({ content: option, language }))
        const optionsWithProduct = options.map(option => ({
          ...option,
          product: hit,
          productSlug: `${hit.productId}.${option.slug}`, // Add product slug for linked products
        }))
        return [...acc, ...optionsWithProduct]
      }, [...product.options])
      product.options = productOptions
    }
  }

  // Add product slug for the original product
  product.options = product.options.map(v => v.productSlug ? v : { ...v, productSlug: `${product.id}.${v.slug}` })
  product.options = sortBy(product.options, `productSlug`)

  return product
}

export function getSkuOptions(levelOptions, selectedSlugs, isValid) {
  let skuOptions = {
    price: {
      list: {},
      sale: {},
    },
  }

  for (const slug in selectedSlugs) {
    skuOptions = {
      ...skuOptions,
      ...(levelOptions[slug].find(option => selectedSlugs[slug] === option.slug)),
      ...(isValid === false ? { availability: AVAILABILITY_NOTAVAILABLE } : {}),
      options: selectedSlugs,
    }
  }

  return skuOptions
}


export const normalizeProduct = ({
  product,
  search,
  getPromotions,
}) => {
  const detailProduct = toDetailProduct(product)
  const selectedSlugs = getSelectedSlugs(detailProduct, search)
  const levelOptions = getOptionValues(detailProduct, selectedSlugs)
  const skuOptions = getSkuOptions(levelOptions, selectedSlugs)
  const promotions = getPromotions(detailProduct, skuOptions)

  return {
    product: detailProduct,
    selectedSlugs,
    levelOptions,
    skuOptions,
    promotions,
  }
}

/**
 * Option levels represent an array of options to choose from: color, sizeType, size
 * We want to automatically select a color, but we dont want to auto select a size
 * so we can prompt the user to explicitly choose their size.
 *
 * @argument optionLevels: Array<string>
 * @argument selected: {[string]: any]}
 * @returns Array<string>
 */
export function pruneAutoSelectOptionLevels(optionLevels, selected) {
  if (Boolean(optionLevels) === false) {
    return []
  }

  // When there are zero options
  if (optionLevels.length === 0) {
    return []
  }

  // Function to check if levelOption is part of autoSelectedOptions but is not part of selected
  function isAutoSelect(levelOption) {
    return has(selected, levelOption) === false && optionLevels.includes(levelOption)
  }

  // When both sizeType and size are auto seleced, remove the size option
  if (
    isAutoSelect(LEVEL_OPTION_SIZE_TYPE) &&
    isAutoSelect(LEVEL_OPTION_SIZE)
  ) {
    return optionLevels.filter(opt => opt !== LEVEL_OPTION_SIZE)
  }

  // When only sizeType is auto selected and size option does not exist, remove the sizeType option
  if (
    isAutoSelect(LEVEL_OPTION_SIZE_TYPE) &&
    isAutoSelect(LEVEL_OPTION_SIZE) === false
  ) {
    return optionLevels.filter(opt => opt !== LEVEL_OPTION_SIZE_TYPE)
  }

  // When only size is auto selected and sizeType option does not exist, remove the size option
  if (
    isAutoSelect(LEVEL_OPTION_SIZE_TYPE) === false &&
    isAutoSelect(LEVEL_OPTION_SIZE)
  ) {
    return optionLevels.filter(opt => opt !== LEVEL_OPTION_SIZE)
  }

  // fallback default, no pruning necessary
  return optionLevels
}
